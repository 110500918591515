@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .soc-input-label {
    @apply block overflow-hidden bg-soc-light-surface-container-highest px-[16px] py-[4px] rounded-t-[4px] border-b-2 border-b-soc-light-on-surface-variant focus-within:border-b-soc-light-primary;
  }

  .soc-input-label-span {
    @apply block text-soc-light-on-surface-variant text-sm font-normal;
  }

  .soc-input {
    @apply text-soc-light-on-surface-variant bg-transparent w-full border-none focus:outline-0 p-0 focus:border-transparent focus:outline-none focus:ring-0 focus:bg-transparent text-[16px];
  }
  .soc-button {
    @apply text-soc-light-on-primary bg-soc-light-primary hover:bg-[#0c7377] focus:outline-none py-[10px] rounded-full focus:ring-0 font-medium text-sm w-full text-center disabled:bg-[#006d747a];
  }
  .soc-sidebar-lnik {
    @apply flex items-center p-4 rounded-full hover:bg-soc-light-surface-secondary-container;
  }
  .soc-sidebar-lnik-span {
    @apply ml-3 text-base font-semibold text-soc-light-on-surface-variant group-hover:text-soc-light-surface-on-secondary-container;
  }
  .active {
    @apply bg-soc-light-surface-secondary-container;
  }
  .active .soc-sidebar-lnik-span {
    @apply text-soc-light-surface-on-secondary-container font-bold;
  }
}

.focunLevel:focus-within .focusSpan {
  color: #00696e;
}

div[data-tag="allowRowEvents"] {
  width: 100%;
}

.newAssetPond .filepond--drop-label {
  background: #fff;
  border: 1.5px dotted #00696e;
  border-radius: 14px;
}
.newAssetPond .filepond--drop-label label {
  color: #00696e;
}
.newAssetPond .filepond--label-action {
  text-decoration-color: #00696e;
  -moz-text-decoration-color: #00696e;
}
