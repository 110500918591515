@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Open Sans Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.sidebar-open::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure it's above the sidebar */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.popup-content {
  width: auto;
}

.image-crop-preview {
  max-width: 600px !important;
  max-height: 600px !important;
}
.filepond--credits {
  display: none;
}

.textAlignCenter {
  width: 100%;
  text-align: center;
}

.textAlignLeft {
  width: 100%;
  text-align: center;
}

.textAlignRight {
  width: 100%;
  text-align: center;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}
.tagsselect button {
  width: 100%;
}
.tagsselect button svg {
  margin-left: auto !important;
}

.tagsselect div ul {
  height: 200px;
  overflow: auto;
}

@media only screen and (max-width: 768) {
  .tagsselect div {
    min-width: 100% !important;
  }
}

.ag-theme-quartz {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
  /* color: #000 !important; */
  /*--ag-foreground-color: rgb(63 73 73);*/
  /* Changes the color of the grid background */
  --ag-background-color: rgb(255, 255, 255);
  /* Changes the header color of the top row */
  --ag-header-foreground-color: rgb(25, 28, 28);
  --ag-header-background-color: rgb(218, 228, 229);
  /* Changes the hover color of the row*/
  --ag-row-hover-color: rgb(238, 238, 238);
}
